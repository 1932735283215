import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
// Import Vuetify styles
import 'vuetify/styles'

// Import MDI CSS
import '@mdi/font/css/materialdesignicons.css'

const app = createApp(App)

app.use(router)
app.use(store)
app.use(vuetify)
app.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});

app.mount('#app')
