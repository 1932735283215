import { createRouter, createWebHistory } from 'vue-router';
import { useToast } from "vue-toastification";
import Layout from '../components/Layout.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import ResetPassword from '../views/ResetPassword.vue';

import store from '@/store';
import SetNewPassword from '../views/SetNewPassword.vue';
import Dashboard from '@/views/Dashboard.vue';
import { dashboardItems } from '@/navigationConfig';

const generateRoutes = (items) => {
  return items.flatMap(item => {
    const routes = [];
    if (item.route) {
      routes.push({
        path: item.route,
        name: item.title,
        component: () => import(`@/views/${item.component}.vue`),
        meta: {
          requiresAuth: true,
          permission: item.permission || []
        }
      });
    }
    if (item.children) {
      routes.push(...generateRoutes(item.children));
    }
    return routes;
  });
};

const routes = [
    {
        path: '/',
        component: Layout,
        meta: { requiresAuth: true },
        children: [
            { name: 'Dashboard', path: '', component: Dashboard },
            ...generateRoutes(dashboardItems)
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
    },
    {
        path: '/reset-password/:token',
        name: 'SetNewPassword',
        component: SetNewPassword,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const toast = useToast();
    const isAuthenticated = store.getters.isLoggedIn;
    const requiredPermissions = to.meta.permission || [];
    const currentPermissions = store.getters.currentPermissions;

    console.log(`Navigation to:`, to.name);
    console.log(`Meta:`, JSON.stringify(to.meta, null, 2));
    console.log(`Is authenticated:`, isAuthenticated);
    console.log(`Required permissions:`, requiredPermissions);
    console.log(`Current permissions:`, JSON.stringify(currentPermissions, null, 2));

    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        console.log(`Redirecting to login: Auth required but not authenticated`);
        next('/login');
    } else if (to.name === 'Login' && isAuthenticated) {
        console.log(`Redirecting to home: Already authenticated`);
        next('/');
    } else if (requiredPermissions.length > 0) {
        const hasAllPermissions = requiredPermissions.every(permission => 
            store.getters.hasPermission(permission)
        );
        console.log(`Has all required permissions:`, hasAllPermissions);
        if (hasAllPermissions) {
            next();
        } else {
            console.log(`Showing toast: Missing required permission`);
            toast.error("You don't have access. Please contact an administrator.", {
                timeout: 5000
            });
            next(false); // Abort the navigation
        }
    } else {
        next();
    }
});

export default router;
