import axios from 'axios';
import router from '@/router'; // Import your Vue Router instance
import store from '@/store';
import { useToast } from "vue-toastification"; // Import the toast function

const toast = useToast();

const getBaseUrl = () => {
  // Check if window.ENV exists (set by our runtime config)
  if (window.ENV && window.ENV.VUE_APP_API_BASE_URL) {
    return window.ENV.VUE_APP_API_BASE_URL;
  }
  // Fallback to the build-time environment variable
  return process.env.VUE_APP_API_BASE_URL || 'http://localhost:3000';
};

const apiClient = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401 && error.config.url !== '/api/auth/login') {
      store.dispatch('logout');
      toast.error("Your session has expired. Please log in again.");
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

console.log('API Base URL:', getBaseUrl());

// Function to lookup products
export const lookupProduct = async (query, page = 1, itemsPerPage = 5) => {
  const response = await apiClient.get('api/products', {
    params: {
      query: query,
      page: page,
      per_page: itemsPerPage
    }
  });
  return response;
};



// Function to save batch code information
export const saveBatchCode = (batchCodeData) => {
  if (batchCodeData._id) {
    // If the batch code has an ID, it's an update
    return apiClient.put(`/api/batch-codes/${batchCodeData._id}`, batchCodeData);
  } else {
    // If there's no ID, it's a new batch code
    return apiClient.post('/api/batch-codes', batchCodeData);
  }
};

// Function to search batch codes
export const searchBatchCodes = (query) => {
  return apiClient.get(`/api/batch-codes`, { params: { query } });
};

// Function to get a specific batch code by ID
export const getBatchCodesByProductId = (id) => {
  console.log('getBatchCodesByProductId',id);
  return apiClient.get(`/api/batch-codes/${id}`);
};

// Function to get all batch codes with pagination and search
export const getBatchCodes = async (params) => {
  const response = await apiClient.get('/api/batch-codes', { params });
  return response.data;
};

// Function to delete a batch code
export const deleteBatchCode = (id) => {
  return apiClient.delete(`/api/batch-codes/${id}`);
};

// Upload files to the server
export const uploadFiles = (formData) => {
  return apiClient.post('/api/invoices/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// New function to get processing updates
export const getProcessingUpdates = (onUpdate, onDone) => {
  const eventSource = new EventSource(`${getBaseUrl()}/api/invoices/processing-updates`);

  eventSource.onmessage = (event) => {
    try {
      if (event.data && event.data !== "undefined") {
        const data = JSON.parse(event.data);
        if (data.done) {
          eventSource.close();
          onDone();
        } else {
          onUpdate(data);
        }
      } else {
        console.warn('Received empty or undefined event data');
      }
    } catch (error) {
      console.error('Error parsing event data:', error);
    }
  };

  eventSource.onerror = (error) => {
    console.error('EventSource failed:', error);
    eventSource.close();
  };

  return () => {
    eventSource.close();
  };
};

// Function to get invoices with pagination, sorting, and filtering
export const getInvoices = (page, itemsPerPage, sortBy, sortDesc, query) => {
  const params = {
    page,
    itemsPerPage,
    sortBy,
    sortDesc,
    query,
  };
  return apiClient.get('/api/invoices', { params });
};

// Function to get a specific invoice by ID
export const getInvoiceById = (invoiceId) => {
  return apiClient.get(`/api/invoices/${invoiceId}`);
};

// Function to create a new invoice
export const createInvoice = (invoiceData) => {
  return apiClient.post('/api/invoices', invoiceData);
};

// Function to update an existing invoice
export const updateInvoice = (invoiceId, invoiceData) => {
  // Ensure that productLines are properly formatted
  const formattedInvoiceData = {
    ...invoiceData,
    productLines: invoiceData.productLines.map(line => ({
      ...line,
      price: line.newPrice || line.price // Use newPrice if it exists, otherwise use the original price
    }))
  };

  return apiClient.put(`/api/invoices/${invoiceId}`, formattedInvoiceData);
};

// Function to delete an invoice
export const deleteInvoice = (invoiceId) => {
  return apiClient.delete(`/api/invoices/${invoiceId}`);
};

// Add this function to fetch entities
export const getEntities = () => {
  return apiClient.get('/api/invoices/entities');
};

// Function to link a product to an invoice line
export const linkProductToInvoiceLine = (invoiceId, productName, productId, variantId) => {
  return apiClient.post(`/api/invoices/${invoiceId}/lines/${productName}/link-product`, { productId, variantId });
};

// New function to approve an invoice
export async function approveInvoice(invoiceId, approvalData) {
  try {
    const response = await apiClient.post(`/api/invoices/${invoiceId}/approve`, approvalData);
    return response.data;
  } catch (error) {
    console.error('Error approving invoice:', error);
    throw error;
  }
}

// Function to get BOMs with search functionality
export const getBOMs = async ({ search = '', page = 1, limit = 10 }) => {
  const response = await apiClient.get('/api/bom', { 
    params: { search, page, limit }
  });
  return response.data;
};

export const getBOMById = async (id) => {
  return await apiClient.get(`/api/bom/${id}`);
};

// Function to create a new BOM
export const createBOM = (bomData) => {
  console.log('Creating BOM:', bomData);
  return apiClient.post('/api/bom', bomData);
};

// Function to update an existing BOM
export const updateBOM = (bomId, bomData) => {
  return apiClient.put(`/api/bom/${bomId}`, bomData);
};

// Function to delete a BOM
export const deleteBOM = (bomId) => {
  return apiClient.delete(`/api/bom/${bomId}`);
};

// Add these functions to the existing apiClient.js file

export const getManufacturingOrders = async ({ search = '', page = 1, itemsPerPage = 10 }) => {
  return await apiClient.get('/api/manufacturing-orders', {
    params: { search, page, itemsPerPage }
  });
};

export const createManufacturingOrder = async (orderData) => {
  return await apiClient.post('/api/manufacturing-orders', orderData);
};

export const updateManufacturingOrder = async (orderId, orderData) => {
  return await apiClient.put(`/api/manufacturing-orders/${orderId}`, orderData);
};

export async function updateManufacturingOrderStatus(orderId, newStatus) {
  try {
    const response = await apiClient.patch(`/api/manufacturing-orders/${orderId}/status`, { status: newStatus });
    return response.data;
  } catch (error) {
    console.error('Error updating manufacturing order status:', error);
    throw error;
  }
}

export const deleteManufacturingOrder = async (orderId) => {
  return await apiClient.delete(`/api/manufacturing-orders/${orderId}`);
};

export const getWarehouses = async () => {
  return await apiClient.get('/api/warehouse');
};

export const getPurchaseOrders = async (params) => {
  try {
    const response = await apiClient.get('/api/purchase-orders', { params });
    return response.data || { purchaseOrders: [], total: 0 };
  } catch (error) {
    console.error('Error fetching purchase orders:', error);
    throw error;
  }
};

export const createPurchaseOrderInvoice = async (purchaseOrderId) => {
  return await apiClient.post(`/api/purchase-orders/${purchaseOrderId}/create-invoice`);
};

export const downloadPurchaseOrderPDF = async (purchaseOrderId) => {
  try {
    const response = await apiClient.get(`/api/purchase-orders/${purchaseOrderId}/pdf`, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `purchase_order_${purchaseOrderId}.pdf`;
    link.click();
    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('Error downloading purchase order PDF:', error);
    throw error;
  }
};

//user management
export const getUsers = async ({ search = '', page = 1, limit = 10 }) => {
  console.log('Getting users with search:', search);
  return await apiClient.get('/api/auth/users', { params: { search, page, limit } });
};

export const createUser = async (userData) => {
  return await apiClient.post('/api/auth/register', userData);
};

export const updateUserStatus = async (userId, status) => {
  return await apiClient.patch(`/api/auth/users/${userId}/status`, { status });
};

export const resetUserPassword = async (userId) => {
  return await apiClient.post(`/api/auth/admin-reset-password/${userId}`);
};

// User-initiated password reset
export const requestPasswordReset = async (email) => {
  return await apiClient.post('/api/auth/reset-password', { email });
};

export const updateUser = async (userId, userData) => {
  return await apiClient.put(`/api/auth/users/${userId}`, userData);
};

// SKU codes
export const generateSKUCode = async (skuData) => {
  return await apiClient.post('/api/products/sku-codes', skuData);
};

// Product management
export const getMissingSkuProducts = async (entityId, params) => {
  return await apiClient.get(`/api/products/missing-skus/${entityId}`, { params });
};

export const updateProductSkus = async (entityId, products) => {
  return await apiClient.post(`/api/products/update-skus/${entityId}`, { products });
};


export async function getSuppliers() {
  return await apiClient.get('/api/products/suppliers');
}

export async function getCategories(entityId) {
  return await apiClient.post('/api/products/categories', { entityId });
}

// Stock take

export const getStockTakes = async ({ search = '', page = 1, itemsPerPage = 10 }) => {
  try {
    const response = await apiClient.get('/api/stock-takes', {
      params: { search, page, itemsPerPage }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching stock takes:', error);
    throw error;
  }
};

export const getStockTakeById = async (id) => {
  try {
    const response = await apiClient.get(`/api/stock-takes/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching stock take:', error);
    throw error;
  }
};

export const createStockTake = async (stockTakeData) => {
  try {
    const response = await apiClient.post('/api/stock-takes', stockTakeData);
    return response.data;
  } catch (error) {
    console.error('Error creating stock take:', error);
    throw error;
  }
};

export const getLocations = async (entityId) => {
  return await apiClient.post('/api/stock-takes/locations', { entityId });
};

export const getBrands = async (entityId) => {
  return await apiClient.post('/api/products/brands', { entityId });
};

export const updateStockTake = async (id, stockTakeData) => {
  try {
    const response = await apiClient.put(`/api/stock-takes/${id}`, stockTakeData);
    return response.data;
  } catch (error) {
    console.error('Error updating stock take:', error);
    throw error;
  }
};

export const deleteStockTake = async (id) => {
  try {
    const response = await apiClient.delete(`/api/stock-takes/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting stock take:', error);
    throw error;
  }
};


export const updateStockTakeStatus = async (stockTakeId, newStatus) => {
  const response = await apiClient.put(`/api/stock-takes/${stockTakeId}/status`, { status: newStatus });
  return response.data;
};

// Function to download stock sheet PDF
export const downloadStockSheetPdf = async (stockTakeId) => {
  return apiClient.get(`/api/stock-takes/${stockTakeId}/generate-stock-sheet`, {
    responseType: 'blob',
  });
};

// Add this new function
export const updateStockTakeItemCount = async (stockTakeId, itemUpdate) => {
  try {
    const response = await apiClient.put(`/api/stock-takes/${stockTakeId}/item-count`, itemUpdate);
    return response.data;
  } catch (error) {
    console.error('Error updating stock take item count:', error);
    throw error;
  }
};

export const getInventorySnapshots = (params) => {
  return apiClient.get('/api/inventory-snapshots', { params });
};

export const createInventorySnapshot = (data) => {
  return apiClient.post('/api/inventory-snapshots', data);
};

export const deleteInventorySnapshot = (id) => {
  return apiClient.delete(`/api/inventory-snapshots/${id}`);
};

export const downloadInventorySnapshot = async (id) => {
  try {
    const response = await apiClient.get(`/api/inventory-snapshots/${id}/download`, {
      responseType: 'blob'
    });
    
    // Get the filename from the Content-Disposition header
    console.log('response headers:', JSON.stringify(response.headers));
    const contentDisposition = response.headers['content-disposition'];
    let filename = 'inventory_snapshot.xlsx';
    if (contentDisposition) {
      const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
      if (filenameMatch && filenameMatch.length === 2) {
        filename = filenameMatch[1].replace(/['"]/g, ''); // Remove any quotes
      }
    }

    // If we couldn't get the filename from the header, generate one based on the current date
    if (filename === 'inventory_snapshot.xlsx') {
      const currentDate = new Date().toISOString().split('T')[0];
      filename = `inventory_snapshot_${currentDate}.xlsx`;
    }

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('Error downloading inventory snapshot:', error);
    throw error;
  }
};

//logistics reconciliation
export const getInvoicesForReconciliation = async (params) => {
  return await apiClient.get('/api/logistics/reconciliation', { params });
};

export const generateInvoiceCrossCharges = async (invoiceId, account) => {
  try {
    const response = await apiClient.post(`/api/logistics/reconciliation/${invoiceId}/generate-cross-charges`, {
      account
    });
    return response;  // The response should include the updated invoice data
  } catch (error) {
    // If the server sends a specific error message, pass it through
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    }
    // Otherwise, throw the original error
    throw error;
  }
};

//upload invoices
export const uploadInvoice = async (formData) => {
  return await apiClient.post('/api/logistics/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

// Add this new function to your apiClient.js
export const deleteLogisticsInvoice = async (invoiceId) => {
  return await apiClient.delete(`/api/logistics/invoices/${invoiceId}`);
};

// Add this new function to get shipping providers
export const getShippingProviders = async () => {
  return await apiClient.get('/api/logistics/providers');
};

//wholesale orders
export const getWholesaleOrders = async ({ search = '', page = 1, limit = 10 }) => {
  try {
    const response = await apiClient.get('/api/wholesale-orders', {
      params: { search, page, limit }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching wholesale orders:', error);
    throw error;
  }
};

export const createWholesaleOrderInvoice = async (orderId) => {
  return await apiClient.post(`/api/wholesale-orders/${orderId}/create-invoice`);
};


export default apiClient;




